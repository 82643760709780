.likert7Question {
    margin-bottom: 50px;
    padding-bottom: 50px;
    border-bottom: 1px solid #D2D8DB;
    width: 100%;
    flex-basis: 100%;
}

.likert7Question .title {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 0.8rem;
}

.likert7Question .options .items {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

/********************
     Item Styling
********************/
.likert7Question .options .items .item {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 3px solid;
    border-radius: 50%;
    cursor: pointer;
    transition: all .2s ease-in-out;
}

.likert7Question .options .items .item > * {
    pointer-events: none;
}

.likert7Question .options .items .item i {
    display: none;
}

.likert7Question .options .items .item.max i {
    font-size: 1em;
    margin-bottom: -2.5px;
}

.likert7Question .options .items .item.med i {
    font-size: 0.8em;
    margin-bottom: -2.5px;
}

.likert7Question .options .items .item.min i {
    font-size: 0.7em;
    margin-bottom: -1px;
}

.likert7Question .options .items .item.neutral i {
    font-size: 0.6em;
    margin-bottom: -2px;
    margin-left: -1px;
}

.likert7Question .options .items .item.disagree.max {
    width: 50px;
    height: 50px;
    border: 3px solid #FF6B6B;
}

.likert7Question .options .items .item.disagree.med {
    width: 40px;
    height: 40px;
    border: 3px solid #FF6B6B;
}

.likert7Question .options .items .item.disagree.min {
    width: 30px;
    height: 30px;
    border: 3px solid #FF6B6B;
}

.likert7Question .options .items .item.neutral {
    width: 25px;
    height: 25px;
    border: 3px solid #849BA0;
}

.likert7Question .options .items .item.agree.min {
    width: 30px;
    height: 30px;
    border: 3px solid #1EA185;
}

.likert7Question .options .items .item.agree.med {
    width: 40px;
    height: 40px;
    border: 3px solid #1EA185;
}

.likert7Question .options .items .item.agree.max {
    width: 50px;
    height: 50px;
    border: 3px solid #1EA185;
}

.likert7Question .options .items .item.active i, .likert7Question .options .items .item:hover i {
    display: inline-block;
    color: rgba(255, 255, 255, 1);
}

.likert7Question .options .items .item.disagree.active {
    background-color: #FF6B6B;
}

.likert7Question .options .items .item.neutral.active {
    background-color: #849BA0;
}

.likert7Question .options .items .item.agree.active {
    background-color: #1EA185;
}

.likert7Question .options .items .item.disagree:hover {
    background-color: #FF6B6B;
}

.likert7Question .options .items .item.neutral:hover {
    background-color: #849BA0;
}

.likert7Question .options .items .item.agree:hover {
    background-color: #1EA185;
}

/********************
        Labels
********************/
.likert7Question .label-mobile {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.likert7Question .label-mobile .label {
    max-width: 20%;
    margin-top: 10px;
}

.likert7Question .label.disagree {
    text-align: left;
}

.likert7Question .label.agree {
    text-align: right;
}

.likert7Question .label {
    font-weight: 400;
    color: #3D5467;
    font-size: 0.9em;
}

.likert7Question .options .label {
    display: none;
}

/********************
    Media Queries
********************/

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {

}

/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media (min-width: 768px) {
    .likert7Question .options .label {
        display: inline-block;
        text-align: center;
    }

    .likert7Question .label-mobile {
        display: none;
    }

    .likert7Question .options {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .likert7Question .options .items {
        flex-basis: 60%;
    }

    .likert7Question .options .label {
        flex-basis: 17%;
    }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
    .likert7Question {
        flex-basis: 48%;
        border-bottom: 0;
    }

    .likert7Question .options .label {
        display: none;
    }

    .likert7Question .label-mobile {
        display: flex;
    }

    .likert7Question .options .items {
        flex-basis: 100%;
    }

    .likert7Question .title {
        min-height: 2.3em;
    }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
    .likert7Question .options .label {
        display: inline-block;
    }

    .likert7Question .label-mobile {
        display: none;
    }

    .likert7Question .options .items {
        flex-basis: 60%;
    }
}

@media (min-width: 1300px) {
    .likert7Question {
        flex-basis: 29%;
    }

    .likert7Question .options .label {
        display: none;
    }

    .likert7Question .label-mobile {
        display: flex;
    }

    .likert7Question .options .items {
        flex-basis: 100%;
    }
}