.result-type {
    background-color: #f8f8f8;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
    display: flex;
    align-items: center;
}

.result-type .content-type {
    width: 100%;
    padding-top: 3em;
    padding-bottom: 3em;
    margin: auto;
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
}
.result-type .content-type-title {
    width: 100%;
    padding-top: 2em;
    margin: auto;
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
    margin-bottom: -40px;
}

.result-type .content-type .left {
    flex-basis: 80%;
    margin-top: 30px;
    text-align: center;
    max-width: 80%;
    word-wrap: break-word;
}

.result-type .content-type .left .title {
    font-weight: 400;
    font-size: 1.5em;
    color: #3D5467;
    margin-bottom: 20px;
}

.result-type .content-type-title .title-multiple {
    font-weight: 400;
    font-size: 1.5em;
    color: #3D5467;
    margin-bottom: 20px;
    flex-basis: 80%;
    text-align: center;
}

.result-type .content-type .left .text {
    font-weight: 300;
    font-size: 1em;
    line-height: 1.5em;
    color: #424242;
}
.result-type .content-type .left .text-bold {
    font-weight: 600;
}

.result-type .content-type .left .share {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    flex-flow: row wrap;
}

.result-type .content-type .left .share .react-share__ShareButton {
    margin-left: 10px;
    margin-top: 15px;
}

.result-type .content-type .left .share .share-text{
    font-weight: 400;
    font-size: 1.1em;
    line-height: 1.5em;
    color: #3D5467;
    flex-basis: 100%;
}

.result-type .carousel {
    width: 100%;
}

.result-type .slider-wrapper {
    position: relative;
    margin: 0 auto;
    width: 100%;
}

.result-type .slider-wrapper .slider-dots {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    margin-top: -30px;
}
.result-type .slider-wrapper .slider-dots button {
    height: 15px;
    width: 15px;
    max-height: 15px;
    max-width: 15px;
    min-height: 15px;
    min-width: 15px;
    padding: 0;
    border-radius: 50%;
    margin: 5px;
    background-color: #cdcdcd;
    border: none;
    z-index: 9999;
}
.result-type .slider-wrapper .slider-dots button:hover {
    background-color: #1EA185;
    cursor: pointer;
}
.result-type .slider-wrapper .slider-dots button:active {
    border: none;
    outline: none;
}
.result-type .slider-wrapper .slider-dots button:disabled {
    cursor: default;
    background-color: #1EA185;
}

.result-type .slider-next, .result-type .slider-back {
    position: absolute;
    top: 45%;
    z-index: 9999;
    background-color: transparent;
    border: none;
    /* fill: #cdcdcd; */
    fill: #1EA185;
    transition: fill 0.2s ease;
    width: 3em;
    height: 3em;
}

.result-type .slider-next:hover, .result-type .slider-back:hover {
    fill: #1EA185;
}

.result-type .slider-next:active, .result-type .slider-back:active {
    border: none;
    outline: none;
}

.result-type .slider-next:disabled, .result-type .slider-back:disabled {
    cursor: default;
    display: none;
}

.result-type .slider-back{
    left: 0.2rem;
}

.result-type .slider-next{
    right: 0.2rem;
}

.desktop-loading-placeholder {
    display: none;
}

.other-results .other-results-content .button-content {
    display: flex;
    align-items: center;
    justify-content: center;
}

.other-results .other-results-content .thankyou-content {
    background-color: #f8f8f8;
    text-align: center;
    width: 100%;
    margin: auto;
    border-radius: 10px;
    border: 1px solid #eee;
    margin-bottom: 20px;
}

.other-results .other-results-content .thankyou-content .p{
    margin: 20px;
}

.other-results .other-results-content .thankyou-content .link{
    color: #1EA185;
}

.button-content .button-about {
    padding-top: 50px;
    padding-bottom: 50px;
}

.button-content .button-link{
    margin: auto;
    border-radius: 10px;
    border: 2px solid #1EA185;
    padding: 15px 10px;
    color: #424242;
    font-weight: 400;
    font-size: 0.9em;
    text-decoration: none;
    text-align: center;
}

.button-content .button-link:hover{
    color: #fff;
    background-color: #1EA185;
}

.prolific-redirect {
    height: 100px;
    background-color: #1EA185;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
}

.prolific-redirect .button-prolific {
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 2px solid rgba(255, 255, 255, 0);
    padding-bottom: 2px;
    padding-left: 5px;
    margin: 0 0 -4px -5px;
    transition: border 0.1s ease-in;
}

.prolific-redirect:hover .button-prolific {
    border-bottom: 2px solid #fff;
    padding-bottom: 2px;
}

.prolific-redirect .button-prolific .prolific-text {
    display: inline-block;
    color: #fff;
    font-weight: 600;
    font-size: 1.2em;
    margin-right: 0.2em;
}

.prolific-redirect .arrow {
    background-color: transparent;
    fill: #fff;
    width: 1em;
    height: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: -3px;
}

.prolific-redirect:hover .arrow {

}

.result-type .content-type .right {
    flex-basis: 30%;
}

.type-placeholder .content-type .right {
    flex-basis: 50%;
}

.result-type .content-type .right .hexadguy {
    height: 200px;
    width: 200px;
    overflow: hidden;
    margin: 0 auto;
}

.result-type .content-type .right .hexadguy .hexadguy-image {
    height: 100%;
    width: 100%;
}

.other-results .other-results-content {
    width: 80%;
    padding-top: 8%;
    padding-bottom: 1%;
    margin: auto;
}

.other-results .other-results-content .h1{
    color: #3D5467;
    font-weight: 600;
    font-size: 1.2em;
    padding-bottom: 10px;
}

.other-results .other-results-content .p{
    color: #4e4e4e;
    font-weight: 300;
    font-size: 1em;
    line-height: 1.5em;
    margin-bottom: 20px;
}

.other-results .other-results-content .result-bars-content {
    display: flex;
    align-items: center;
    justify-content: left;
    flex-flow: row wrap;
    margin: 10px auto;

}

/* Source of inverted text color: https://stackoverflow.com/questions/21909494/how-to-create-a-progressbar-with-inverted-text-color-for-progressed-part */
.other-results .other-results-content .result-bars-content .hexad-type {
    flex-basis: 100%;
    margin-top: 20px;
}

.other-results .other-results-content .result-bars-content .hexad-type .title {
    color: #424242;
    font-weight: 400;
    font-size: 1.1em;
    margin-bottom: 10px;
}

.other-results .other-results-content .result-bars-content .hexad-type .title .link{
    text-decoration: none;
    color: #424242;
}

.other-results .other-results-content .result-bars-content .hexad-type .description {
    color: #424242;
    font-weight: 300;
    font-size: 0.9em;
    margin-bottom: 10px;
}

.other-results .other-results-content .result-bars-content .hexad-type .progress {
    position: relative;
    display: flex;
    height: 1.7em;
    width: 100%;
    overflow: hidden;
}

.other-results .other-results-content .result-bars-content .hexad-type .progress .front {
    position: absolute;
    left: -1px;
    right: -1px;
    top: -1px;
    bottom: -1px;
    background-color: #f8f8f8;
    color: #424242;
    font-weight: 300;
    display: flex;
    align-items: center;
    transition: clip-path 1s ease-in-out;
    clip-path: inset(0 0 0 0%);
    -webkit-clip-path: inset(0 0 0 0%);
}

.other-results .other-results-content .result-bars-content .hexad-type .progress .back {
    display: flex;
    align-items: center;
    width: 100%;
    color: #fff;
}

.hexad-type .progress .label{
    position: absolute;
    right: 0.5em;
}

.other-results .other-results-content .result-bars-content .hexad-type .progress .back.freeSpirit {
    background-color: #BED44F;
}

.other-results .other-results-content .result-bars-content .hexad-type .progress .back.philanthropist {
    background-color: #6FDFDF;
}

.other-results .other-results-content .result-bars-content .hexad-type .progress .back.player {
    background-color: #F84B69;
}

.other-results .other-results-content .result-bars-content .hexad-type .progress .back.socializer {
    background-color: #FFD358;
}

.other-results .other-results-content .result-bars-content .hexad-type .progress .back.achiever {
    background-color: #3D5467;
}

.other-results .other-results-content .result-bars-content .hexad-type .progress .back.disruptor {
    background-color: #1EA185;
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {  
    .result-type .content-type .right .hexadguy {
        height: 250px;
        width: 250px;
    }
    .other-results .other-results-content {
        padding-top: 6%;
    }
    .other-results .other-results-content .thankyou-content .p{
        margin: 30px;
    }
}
 
/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media (min-width: 768px) {  
    .result-type .slider-wrapper {
        width: 80%;
    }

    .result-type .slider-back{
        left: -4rem;
    }
    
    .result-type .slider-next{
        right: -4rem;
    }

    .other-results .other-results-content {
        padding-top: 4%;
        padding-bottom: 1%;
    }

    .other-results .other-results-content .result-bars-content .hexad-type {
        margin-top: 30px;
    }
    

    .result-type .slider-next, .result-type .slider-back {
        width: 3.5em;
        height: 3.5em;
    }
    
}
 
/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {

    .result-type .content-type {
        padding-top: 4em;
        padding-bottom: 4em;
        justify-content: space-between;
    }
    .result-type .content-type-title {
        padding-top: 4em;
        justify-content: left;
        flex-flow: row wrap;
        margin-bottom: -60px;
    }

    .result-type .content-type-title .title-multiple{
        text-align: left;
    }
    
    .result-type .content-type .left {
        flex-basis: 63%;
        margin-top: 0px;
        order: 1;
        text-align: left;
    }

    .result-type .content-type .left .share {
        justify-content: left;
    }

    .result-type .content-type .left .share .share-text{
        flex-basis: unset;
        display: inline-block;
        margin-right: 0.7em;
    }

    .result-type .content-type .left .share .react-share__ShareButton {
        margin-top: 5px;
    }

    .result-type .content-type .right {
        flex-basis: 35%;
        order: 2;
    }
    .result-type .content-type .right .hexadguy {
        height: 100%;
        width: 100%;
        max-height: 300px;
        max-width: 300px;
    }

    .desktop-loading-placeholder {
        display: block;
    }

    .mobile-loading-placeholder {
        display: none;
    }

    .other-results .other-results-content .result-bars-content {
        margin: 0 auto;
        justify-content: space-between;
    }

    .other-results .other-results-content .result-bars-content .hexad-type {
        flex-basis: 48%;
        width: 95%;
    }

    .other-results .other-results-content {
        padding-top: 3%;
    }
    .other-results .other-results-content .button-content {
        margin-top: 30px;
    }
    .other-results .other-results-content .thankyou-content {
        width: 80%;
    }
}
 
/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {  

    .slider-wrapper {
        max-width: 1100px;
    }

    .result-type .slider-back{
        left: -6rem;
    }
    
    .result-type .slider-next{
        right: -6rem;
    }

    .result-type .content-type {
        padding-top: 5em;
        padding-bottom: 5em;
    }
    .result-type .content-type-title {
        padding-top: 5em;
    }

    .other-results .other-results-content {
        max-width: 1300px;
        padding-top: 2%;
    }

    .result-type .content-type .left .title {
        font-size: 1.7em;
    }
    
    .result-type .content-type .left .text {
        font-size: 1em;
    }
    .result-type .content-type .right .hexadguy {
        margin-top: 0px;
        margin-bottom: 0px;
    }
    .other-results .other-results-content .thankyou-content {
        width: 70%;
    }
}