/************* Mobile ************/
/* .progress-bar-mobile {
    width: 80%;
    margin: -10px auto 30px auto;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
}

.progress-bar-mobile a {
    text-decoration: none;
}

.progress-bar-mobile .progress-circle-mobile {
    background-color: transparent;
    border: 2px solid #eee;
    border-radius: 100%;
    width: 30px;
    height: 30px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.7em;
    position:relative;
}

.progress-bar-mobile .bullet-line-mobile {
    width: 100%;
    height: 2px;
    background-color: #eee;
}

.progress-bar-mobile .progress-circle-mobile.done {
    background-color: #1EA185;
    border: 2px solid #1EA185;
}

.progress-bar-mobile .progress-circle-mobile .active-indicator-mobile {
    height: 14px;
    width: 14px; 
    background-color: #eee;
    position: absolute;
    border-radius: 100%;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.1s linear;
}

.progress-bar-mobile .progress-circle-mobile i {
    font-size: 1.2em;
    margin-bottom: -1px;
}

.progress-bar-mobile .progress-circle-mobile.done i {
    visibility: visible;
    opacity: 100;
    transition: visibility 0s, opacity 0.1s linear;
}

.progress-bar-mobile .progress-circle-mobile.active.done i {
    visibility: hidden;
    opacity: 0;
}

.progress-bar-mobile .progress-circle-mobile.active.done .active-indicator-mobile {
    visibility: visible;
    opacity: 100; 
    background-color: transparent;
    border: 2px solid #fff;
    height: 16px;
    width: 16px;
}

.progress-bar-mobile .progress-circle-mobile.active:not(.done) .active-indicator-mobile {
    visibility: visible;
    opacity: 100;
}  */

.progress-bar-mobile-wrapper {
    display: block;
    margin-bottom: 10px;
    margin-top: -30px;
}

.progress-bar-mobile {
    width: 100%;
    background-color: rgba(255,255,255, 0.8);
    padding: 20px 0;
}

.progress-bar-mobile-inner {
    width: 80%;
    margin: 0 auto 0 auto;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
}

.progress-bar-mobile svg {
    width: 34px;
    height: 34px;
}

.progress-bar-mobile .bullet-line {
    width: 100%;
    height: 2px;
    background-color: #eee;
}

.progress-bar-mobile .background-circle, .progress-circle {
    stroke-width: 3px;
}

.progress-bar-mobile .background-circle {
    stroke: #eee;
    fill: transparent;
}

.progress-bar-mobile .progress-circle {
    stroke: transparent;
    fill: transparent;
    transform: rotate(-180deg);
    transform-origin: center;
    transform-box: fill-box;
    transition: stroke-dashoffset 0.1s ease, fill 0.2s ease;
}

.progress-bar-mobile svg.done .progress-circle {
    fill: #1EA185;
}

.progress-bar-mobile svg.in-progress .progress-circle {
    stroke: #1EA185;;
}

.progress-bar-mobile .active-circle, .active-done-circle {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.1s linear;
}

.progress-bar-mobile svg.active.done .active-done-circle {
    display: inline-block;
    visibility: visible;
    opacity: 1;
}

.progress-bar-mobile svg.active:not(.done) .active-circle {
    display: inline-block;
    fill: #eee;
    visibility: visible;
    opacity: 1;
}

.progress-bar-mobile .progress-check {
    fill: #fff;
    visibility: visible;
    opacity: 1;
    transition: visibility 0s, opacity 0.1s linear;
}

.progress-bar-mobile svg.active .progress-check {
    visibility: hidden;
    opacity: 0;
}


/******** DESKTOP / TABLET *******/
.progress-bar {
    width: 80%;
    margin: 0 auto 70px auto;
    display: none;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
}

.progress-bar svg {
    width: 36px;
    height: 36px;
}

.progress-bar .bullet-line {
    width: 100%;
    height: 2px;
    background-color: #eee;
}

.progress-bar .background-circle, .progress-circle {
    stroke-width: 3px;
}

.progress-bar .background-circle {
    stroke: #eee;
    fill: transparent;
}

.progress-bar .progress-circle {
    stroke: transparent;
    fill: transparent;
    transform: rotate(-180deg);
    transform-origin: center;
    transform-box: fill-box;
    transition: stroke-dashoffset 0.1s ease, fill 0.2s ease;
}

.progress-bar svg.done .progress-circle {
    fill: #1EA185;
}

.progress-bar svg.in-progress .progress-circle {
    stroke: #1EA185;;
}

.progress-bar .active-circle, .active-done-circle {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.1s linear;
}

.progress-bar svg.active.done .active-done-circle {
    display: inline-block;
    visibility: visible;
    opacity: 1;
}

.progress-bar svg.active:not(.done) .active-circle {
    display: inline-block;
    fill: #eee;
    visibility: visible;
    opacity: 1;
}

.progress-bar .progress-check {
    fill: #fff;
    visibility: visible;
    opacity: 1;
    transition: visibility 0s, opacity 0.1s linear;
}

.progress-bar svg.active .progress-check {
    visibility: hidden;
    opacity: 0;
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
    .progress-bar-mobile .progress-circle-mobile {
        width: 2.2em;
        height: 2.2em;
        font-size: 0.9em;
    }

    .progress-bar-mobile .progress-circle-mobile .active-indicator-mobile {
        width: 1.2em;
        height: 1.2em;
    }

}

/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media (min-width: 768px) {
    .progress-bar-mobile {
        display: none
    }

    .progress-bar {
        display: flex;
    }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {

}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
    .progress-bar {
        max-width: 1300px;
    }

}