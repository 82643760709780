.home {
    background-color: #f8f8f8;
    border-top: 1px solid #eee;
}

.home .content {
    width: 100%;
    padding-top: 5%;
    padding-bottom: 5%;
    margin: auto;
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
}

.home .content .left {
    flex-basis: 80%;
    text-align: center;
}

.home .content .left h1 {
    font-weight: 400;
    font-size: 1.5em;
    color: #3D5467;
}

.home .content .left h2 {
    font-weight: 600;
    font-size: 1em;
    color: #3D5467;
}

.home .content .left p {
    font-weight: 300;
    font-size: 1em;
    line-height: 1.5em;
    color: #424242;
}

.home .content .left a {
    color: #1EA185;
}


.home .content .left .startbutton {
    display: inline-block;
    margin: 2rem 0 0 0;
    border-radius: 10px;
    border: 2px solid #1EA185;
    padding: 15px 10px;
    max-width: 245px;
    min-width: 100px;
    color: #424242;
    font-weight: 400;
    font-size: 0.9em;
    text-decoration: none;
    text-align: center;
    transition: visibility 0.2s;
    position: relative;
    background-color: transparent;
}

.home .content .left .startbutton:not(.disabled):not(.loading):hover {
    background-color: #1EA185;
    color: #fff;
    cursor: pointer;
}

.home .startbutton.disabled {
    cursor: default;
    opacity: 0.5;
}

.home .startbutton span {
    opacity: 1;
    transition: all 0.2s;
}

.home .startbutton.loading span {
    opacity: 0;
}

.home .startbutton.loading::after {
    content: "";
    position: absolute;
    width: 16px;
    height: 16px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    border: 5px solid transparent;
    border-top-color: #1EA185;
    border-radius: 50%;
    animation: button-loading-spinner 1s ease infinite;
}

@keyframes button-loading-spinner {
    from {
        transform: rotate(0turn);
    }

    to {
        transform: rotate(1turn);
    }
}

.home .content .right {
    flex-basis: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.home .content .right .titleguy {
    height: 200px;
    width: 200px;
    overflow: hidden;
    margin-top: 20px;
}

.home .content .right .titleguy .titleguy-image {
    height: 100%;
    width: 100%;
}

.home .acknowledge-privacy-statement {
    display: flex;
    flex-flow: row;
    align-items: center;
    margin-top: 2rem;
    text-align: left;
}

.home .privacy-checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
}

.home .privacy-checkbox-inner {
    min-width: 1.8em;
    min-height: 1.8em;
    max-width: 1.8em;
    max-height: 1.8em;
    border-radius: 0.2em;
    border: 2px solid #1EA185;
    display: inline-block;
    margin: 0 1em 0 0;
    font-size: 0.8em;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #1EA185;
    background-color: inherit;
    transition: all 0.2s;
}

.home .privacy-checkbox-inner i {
    visibility: none;
    font-size: 1em;
    margin-bottom: -2px;
    opacity: 0;
    transition: visibility 0s, opacity 0.2s linear;
}

.home .privacy-checkbox-inner:hover {
    cursor: pointer;
}

.home .privacy-checkbox-inner:hover i {
    visibility: visible;
    opacity: 0.3;
}

.home .privacy-checkbox-inner.active {
    color: #fff;
    background-color: #1EA185;
}

.home .privacy-checkbox-inner.active i {
    visibility: visible;
    opacity: 1;
}

.home .tip, .home .tip a {
    color: #FF6B6B !important;
}

.home .privacy-checkbox-inner.tip {
    border-color: #FF6B6B;
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
    .home .content .right .titleguy {
        height: 250px;
        width: 250px;
    }
}

/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media (min-width: 768px) {
    .home .content {
        width: 80%;
    }

}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {

    .home .content .left {
        flex-basis: 63%;
        order: 1;
        text-align: left;
    }

    .home .content .right {
        flex-basis: 35%;
        order: 2;
    }

    .home .content .right .titleguy {
        height: 100%;
        width: 100%;
        max-height: 300px;
        max-width: 300px;
        margin: 0;
    }

    .home .privacy-checkbox-inner {
        min-width: 1.5em;
        min-height: 1.5em;
        max-width: 1.5em;
        max-height: 1.5em;
    }

    .home .privacy-checkbox-inner i {
        font-size: 0.8em;
    }

    .home .content .left .startbutton .startbutton {
        margin-left: 0;
    }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {

    .home .content {
        max-width: 1300px;
    }

    .home .content .left {
        flex-basis: 55%;
    }

    .home .content .right {
        flex-basis: 30%;
    }

    .home .content .left .title {
        font-size: 1.7em;
    }

    .home .content .left .text {
        font-size: 1.1em;
    }
}
