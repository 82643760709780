.numberInputQuestion {
    width: 100%;
    margin-bottom: 30px;
    position: relative;
}

.numberInputQuestion label {
    display: block;
}

.numberInputQuestion input {
    min-height: 38px;
    padding: 0 8px;
    border: 1px solid rgb(204, 204, 204);
    border-radius: 4px;
    font-size: inherit;
    width: 100%;
    box-sizing: border-box;
    appearance: textfield;
    -moz-appearance: textfield !important;
    -webkit-appearance: textfield !important;
    margin: 0 !important;
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {

}

/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media (min-width: 768px) {

}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
    .question-list .numberInputQuestion {
        max-width: 768px;
        margin-left: auto;
        margin-right: auto;
    }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {

}

@media (min-width: 1300px) {

}
