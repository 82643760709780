.validation-warning {
    background-color: #fff3cd;
    border-radius: 0.5em;
    border: 2px solid #ffeeba;
    color: #856404;
    margin: 0 auto 2rem auto;
}

.validation-warning-inner {
    padding: 10px 10px;
    display: flex;
    flex-flow: row;
    align-items: center;
}

.validation-warning a {
    color: #856404;
    text-decoration: none;
    font-weight: bold;
}

.validation-warning a:hover {
    text-decoration: underline;
}

.validation-warning .validation-warning-icon {
    filter: invert(32%) sepia(48%) saturate(1757%) hue-rotate(25deg) brightness(99%) contrast(97%);
    margin-right: 1em;
}


/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
    .validation-warning {
        width: 60%;
    }
}

/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media (min-width: 768px) {
    .validation-warning {
        width: 80%;
    }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
    .validation-warning {
        max-width: 768px;
    }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {

}

