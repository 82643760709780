.radioButtons label {
    display: inline-block;
    margin: 0 20px 0 5px;
    color: #4e4e4e;
}

.radioButtons.vertical {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    row-gap: 15px;
}

.radioButtonQuestion {
    width: 100%;
    margin-bottom: 30px;
    position: relative;
}

.radioButtonQuestion label {
    display: block;
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {

}

/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media (min-width: 768px) {

}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
    .question-list .radioButtonQuestion {
        max-width: 768px;
        margin-left: auto;
        margin-right: auto;
    }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {

}

@media (min-width: 1300px) {

}

