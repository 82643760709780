.pageDescription {
    background-color:  #f8f8f8;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
    margin-bottom: 50px;    
}

.pageDescription .description {
    width: 80%;
    margin: 0 auto 0px auto;
    padding: 30px 0;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
}

.pageDescription .description .descriptionImage {
    overflow: hidden;
    width: auto;
    height: 150px;
}

.pageDescription .description .descriptionImage .iconImage{
    width: 100%;
    height: 100%;
}
.pageDescription .description .descriptionText {
    font-weight: 300;
    font-size: 1em;
    margin-bottom: 20px;
    text-align: center;
    color: #424242;
    line-height: 1.5em;
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {  
    .pageDescription .description {
        width: 60%;
    }
}
 
/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media (min-width: 768px) {  
    .pageDescription .description {
        flex-flow: row nowrap;
        width: 80%;
    }

    .pageDescription .description .descriptionImage {
        flex-basis: 40%;
        height: 170px;
    }

    .pageDescription .description .descriptionText {
        flex-basis: 60%;
        text-align: left;
        font-size: 1em;
    }
}
 
/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 
    .pageDescription .description .descriptionImage {
        height: 200px;
        flex-basis: 35%;
    }
    .pageDescription .description .descriptionText {
        flex-basis: 60%;
        text-align: left;
        font-size: 1.2em;
        margin-bottom: 0;
    }
}

 
/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {  
    .pageDescription .description {
        max-width: 1300px;
    }
    .pageDescription .description .descriptionText {
        flex-basis: 50%;
    }
    .pageDescription .description .descriptionImage {
        flex-basis: 33%;
    }
}
