.pagination a, .pagination button {
    color: #3D5467;
    background-color: #fff;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 50px;
    border-radius: 10px;
    border: 2px solid #1EA185;
    width: 120px;
    padding: 15px 0;
    font-weight: 400;
    font-size: 0.9em;
    text-decoration: none;
    text-align: center;
    outline: none;
    cursor: pointer;
}

.pagination a:hover, .pagination button:hover:not(.disabled) {
    color: #fff;
    background-color: #1EA185;
}

.pagination button.disabled {
    cursor: default;
    opacity: 0.5;
}

.pagination {
    display: flex;
    justify-content: center;
}