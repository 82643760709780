.privacy {
    background-color: #f8f8f8;
    border-top: 1px solid #eee;
}

.privacy .content {
    width: 80%;
    padding-top: 10%;
    padding-bottom: 10%;
    margin: auto;
}

.privacy .content h1 {
    font-weight: 400;
    font-size: 1.5em;
    color: #3D5467;
}

.privacy .content h2 {
    font-weight: 600;
    font-size: 1em;
    color: #3D5467;
}

.privacy .content p, .privacy .content li {
    font-weight: 300;
    font-size: 1em;
    line-height: 1.5em;
    color: #424242;
}

.privacy .content a {
    color: #1EA185;
}

@media (min-width: 768px) {
    .privacy .content {
        padding-top: 7%;
        padding-bottom: 7%;
    }

}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {

    .privacy .content {
        max-width: 1300px;
        padding-top: 60px;
        padding-bottom: 60px;
    }
}