@import-normalize; /* bring in normalize.css styles */

@import '../assets/css/iconmonstr-iconic-font.min.css';

@import '~animate.css/animate.css';

@font-face {
    font-family: 'Source Sans Pro';
    src: url('../assets/fonts/SourceSansPro-Regular.ttf') format('truetype');
    font-weight: 400;
    font-display: swap;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: url('../assets/fonts/SourceSansPro-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-display: swap;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: url('../assets/fonts/SourceSansPro-Light.ttf') format('truetype');
    font-weight: 300;
    font-display: swap;
}

body {
    margin: 0;
    font-family: 'Source Sans Pro', sans-serif;
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 80%;
    margin: 0 auto;
    height: 5em;
}

.header a {
    text-decoration: none;
}

.header-inner {
    display: flex;
    align-items: center;
    flex-flow: row;
    text-align: center;
}

.header-inner .header-image {
    width: 65px;
    height: 65px;
    overflow: hidden;
    display: flex;
    align-items: center;
    margin-right: 10px;
}

.header-inner .header-image .image-inner {
    max-width: 100%;
    max-height: 100%;
}

.header .language-switcher {
    float: right;
    color: #ccc;
    position: relative;
}

.header .about-hexad-link {
    color: #3D5467;
    margin-left: auto;
    margin-right: 10px;
}

.header .about-hexad-link:hover {
    color: #1EA185;
}

.header .language-switcher svg {
    margin-right: 0.6rem;
}

.header .dropdown {
    position: relative;
    display: flex;
    align-items: center;
    padding: 0.6rem 0;
}

.dropdown-content {
    display: none;
    position: absolute;
    flex-flow: column;
    border: 1px solid #eee;
    border-radius: 2px;
    background-color: #fff;
    color: #bbb;
    z-index: 9999;
}

.dropdown-content div {
    padding: 0.7em 2em;
    transition: all 0.2s ease-in-out;
}

.dropdown-content div:hover {
    background-color: #1EA185;
    color: #fff;
    cursor: pointer;
}

.language-switcher:hover .dropdown-content {
    display: flex;
}

.footer {
    border-top: 1px solid #eee;
}

.footer-inner {
    width: 90%;
    padding-top: 5%;
    padding-bottom: 40px;
    align-items: center;
    margin: 0 auto;
    text-align: center;
    min-height: 5em;
    color: #424242;
    font-weight: 400;
    font-size: 0.8em;
    flex-flow: row wrap;
    display: flex;
    justify-content: center;
}

.footer-inner .footer-text {
    width: 100%;
    margin-bottom: 20px;
}

.footer-inner .footer-text .link {
    color: #1EA185;
}

.footer-inner .footer-link {
    color: #424242;
    font-weight: 400;
    font-size: 1.15em;
    text-decoration: none;
}

.footer-inner .footer-links a:hover {
    color: #1EA185;
}

.footer-inner .footer-links a:last-child {
    margin-left: 2rem;
}

.header h1 {
    font-weight: 400;
    font-size: 0.1em;
    text-align: left;
    color: #3D5467;
    visibility: hidden;
}


#loading-indicator {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9998;
    background-color: rgba(255, 255, 255, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

.question-list {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    width: 80%;
    margin: 0 auto;
}

.no-wrap .question-list {
    flex-flow: column nowrap !important;
}

.question-list .subtitle {
    font-weight: 300;
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
    .question-list {
        width: 60%;
    }

    .header-inner .header-image {
        margin-right: 15px;
    }

    .footer-inner {
        padding-top: 2%;
        padding-bottom: 20px;
    }
}

/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media (min-width: 768px) {

    .header h1 {
        font-size: 1.4em;
        margin: 0;
        visibility: visible;
    }

    .header {
        height: 5em;
        width: 80%;
    }

    .footer-inner {
        width: 80%;
    }

    .question-list {
        width: 80%;
    }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
    .header {
        height: 6em;
    }

    .footer-inner {
        text-align: left;
        padding-top: 2%;
        justify-content: space-between;
        min-height: 7em;
    }

    .footer-inner .footer-text {
        width: auto;
        margin-bottom: 0;
    }

    .header .about-hexad-link {
        margin-right: 30px;
    }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
    .header {
        height: 6em;
        max-width: 1300px;
    }

    .footer-inner {
        max-width: 1300px;
    }

    .question-list {
        max-width: 1300px;
    }

    .footer-inner {
        padding-top: 1%;
    }
}

