.alert-modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    z-index: 100000;
    overflow: hidden;
    outline: 0;
    opacity: 1;
    transition: opacity .15s linear;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

.alert-modal.hide {
    display: none;
}

.alert-modal svg {
    width: 72px;
    height: 72px; 
    fill: #FF6B6B;
    margin-bottom: 1rem;
}

.alert-modal svg path {
    transform: scale(3);
}

.alert-errorcode {
    margin-top: 1em;
    color: #888;
}

.modal-content {
    background-color: white;
    display: inline-block;
    max-width: 700px;
    padding: 4rem 2rem;
    text-align: center;
    margin: 2rem;
    color: #444;
}

.modal-content a {
    color: #FF6B6B;
}

.modal-content h2 {
    margin: 0;
    margin-bottom: 1.5rem;
}

.modal-content .back-link {
    display: inline-block;
    margin: 2rem 0 0 0;
    border-radius: 10px;
    border: 2px solid #FF6B6B;
    padding: 15px 20px;
    max-width: 245px;
    color: #FF6B6B;
    font-weight: 400;
    font-size: 0.9em;
    text-decoration: none;
    text-align: center;
    background-color: #fff;
    cursor: pointer;
    transition: background-color 0.2s linear;
}

.modal-content .back-link:hover {
    background-color: #FF6B6B;
    color: white;
}