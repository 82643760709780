.header-image-container {
    background-color: #f8f8f8;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
    display: flex;
    justify-content: center;
}

.header-image {
    width: 80%;
    max-width: 1000px;
}

.header-image img {
    width: 100%;
}

.about-hexad .content {
    width: 80%;
    margin: auto;
    max-width: 1300px;
    margin-top: 30px;
}

.about-hexad .content .title {
    font-size: 1.5em;
    flex-basis: 100%;
    color: #3D5467;
    text-align: center;
}

.about-hexad .content .text {
    font-size: 1em;
    font-weight: 300;
    color: #4e4e4e;
    margin-top: 20px;
    margin-bottom: 60px;
    text-align: center;
    line-height: 1.5em;
}

.about-hexad .content .type-block {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: row wrap;
    margin-bottom: 50px;
}

.about-hexad .content .type-block .type-image-container {
    flex-basis: 100%;
    min-width: 270px;
    max-width: 300px;
    order: 1;
}

.about-hexad .content .type-block .type-description {
    flex-basis: 100%;
    font-size: 1em;
    font-weight: 300;
    color: #4e4e4e;
    line-height: 1.5em;
    order: 2;
    text-align: center;
    margin-top: 20px;
}

.about-hexad .content .type-block .text-bold{
    font-weight: 600;
    font-size: 1.3em;
}

.type-image-container img {
    margin: 0 auto; 
    display: block;
}

#disruptor .type-image-container img { width: 90% }
#freeSpirit .type-image-container img { width: 90% }
#philanthropist .type-image-container img { width: 90% }
#socializer .type-image-container img { width: 90% }

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {  
    
}
 
/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media (min-width: 768px) {  
    
    
}
 
/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
    .about-hexad .content .title {
        text-align: left;
    }
    .about-hexad .content .text {
        text-align: left;
    }
    .about-hexad .content .type-block .type-image-container {
        flex-basis: 35%;
    }
    
    .about-hexad .content .type-block .type-description {
        flex-basis: 60%;
        margin: 0;
        text-align: left;
    }
    .about-hexad .content .type-block {
        justify-content: space-between;
    }
   
}
 
/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {  
    .about-hexad .content .type-block .type-image-container {
        flex-basis: 30%;
    }
    
    .about-hexad .content .type-block .type-description {
        flex-basis: 70%;
    }
   
}



