.notfound {
    background-color: #f8f8f8;
    border-top: 1px solid #eee;
}
.notfound .content {
    width: 100%;
    padding-top: 5%;
    padding-bottom: 5%;
    margin: auto;
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
}

.notfound .content .left {
    flex-basis: 80%;
    margin-top: 30px;
    text-align: center;
}

.notfound .content .left .title {
    font-weight: 400;
    font-size: 1.5em;
    color: #3D5467;
    margin-bottom: 20px;
}

.notfound .content .left .text {
    font-weight: 300;
    font-size: 1em;
    line-height: 1.5em;
    color: #424242;
}
.notfound .content .left .text .link{
    color: #1EA185;
}
.notfound .content .left .text-bold {
    font-weight: 600;
}
.notfound .content .left .startbutton {
    padding-top: 50px;
    padding-bottom: 50px;
}
.notfound .content .left .startbutton .startbutton-inner{
    margin: auto;
    border-radius: 10px;
    border: 2px solid #1EA185;
    padding: 15px 10px;
    max-width: 245px;
    color: #424242;
    font-weight: 400;
    font-size: 0.9em;
    text-decoration: none;
    text-align: center;
    transition: visibility 0.2s;
}

.notfound .content .left .startbutton-inner:hover{
    background-color: #1EA185;
    color: #fff;
    cursor: pointer;
}

.notfound .startbutton.disabled .startbutton-inner {
    cursor: default;
    opacity: 50%;
}

.notfound .content .right {
    flex-basis: 30%;
}

.notfound .content .right .titleguy {
    height: 200px;
    width: 200px;
    overflow: hidden;
    margin-top: 20px;
}

.notfound .content .right .titleguy .titleguy-image {
    height: 100%;
    width: 100%;
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {  
    .notfound .content .right .titleguy {
        height: 250px;
        width: 250px;
    }
}
 
/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media (min-width: 768px) {  
    .notfound .content {
        width: 80%;   
    }
    
}
 
/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
    
    .notfound .content .left {
        flex-basis: 63%;
        margin-top: 20px;
        order: 1;
        text-align: left;
    }
    .notfound .content .right {
        flex-basis: 35%;
        order: 2;
    }
    .notfound .content .right .titleguy {
        height: 100%;
        width: 100%;
        max-height: 300px;
        max-width: 300px;
        margin-top: 0px;
        margin-left: 20px;
    }

    .notfound .content .left .startbutton .startbutton-inner {
        margin-left: 0;
    }
}
 
/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {  

    .notfound .content {
        max-width: 1300px;
    }
    .notfound .content .left {
        flex-basis: 55%;
    }
    .notfound .content .right {
        flex-basis: 30%;
    }
    .notfound .content .left .title {
        font-size: 1.7em;
    }
    
    .notfound .content .left .text {
        font-size: 1.1em;
    }
    .notfound .content .right .titleguy {
        margin-left: 40px;
    }
}
